import { render, staticRenderFns } from "./BenefitForm.vue?vue&type=template&id=4fd0100e"
import script from "./benefit_form.js?vue&type=script&lang=js&external"
export * from "./benefit_form.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div>
  <div class="row mt-1 align-items-center justify-content-center mb-5">
    <div class="col-12 col-md-4 mt-1 mt-md-0">
      <div class="btn-request-type text-themed-dark d-flex align-items-center justify-content-center"
           :class="{'btn-request-type-active': (currentTab === 'BenefitVoucherList')}"
           @click="currentTab = 'BenefitVoucherList'">
        <div>
          <div class="text-20 text-uppercase">{{ $t("flexben.voucher_stocks.titles.voucher_stocks") }}</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mt-1 mt-md-0">
      <div class="btn-request-type text-themed-dark d-flex align-items-center justify-content-center ml-auto"
           :class="{'btn-request-type-active': (currentTab === 'VoucherInvoicesList')}"
           @click="currentTab = 'VoucherInvoicesList'">
        <div>
          <div class="text-20 text-uppercase">{{ $t("flexben.voucher_stocks.titles.voucher_invoices") }}</div>
        </div>
      </div>
    </div>
  </div>
  <component :is="currentTabComponent" />
  </div>
</template>

<script>

import BenefitVoucherList from './BenefitVoucherList.vue';
import VoucherInvoicesList from './VoucherInvoicesList.vue';

export default {
  name: 'TabsComponent',
  components: {
    BenefitVoucherList,
    VoucherInvoicesList,
  },
  data() {
    return {
      currentTab: 'BenefitVoucherList',
    };
  },
  computed: {
    currentTabComponent() {
      return this.currentTab;
    }
  }
};
</script>

<style scoped>
.btn-request-type {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 0.25rem;
  color: #585858;
  font-size: 14px;
  opacity: 0.9;
  height: 100%;
  text-align: center;
  line-height: 1.1;
  cursor: pointer;
  width: 100%;
  padding: 1.1rem 0.5rem;
  max-width: 350px;
  transition: background-color 300ms ease;

  &.btn-request-type-active, &:hover {
    opacity: 1;
    background: var(--color-f49400);
    border-color: var(--color-f49400);
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0.6rem 0.3rem;
  }
}
</style>

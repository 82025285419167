<template>
  <div>
    <div class="container-bg-white p-3">
      <div class="col-12 col-md-6 pl-sm-0 flexben-page-title">
        <h2 class="p-0 m-0">{{ $tc("flexben.voucher_stocks.titles.import") }}</h2>
      </div>
      <div v-if="importInvalidReason!==null" class="row">
        <div class="col-12 text-danger text-bold text text-center">{{ importInvalidReason }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="btn save-btn text-white px-4 col-form-label" for="import_file">{{
              $t('flexben.import.buttons.upload_file')
              }}</label>
            <input id="import_file" class="hide" type="file"
                   accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   @change="uploadFile($event)"
                   ref="file_input">
          </div>
          <div class="ml-2">{{importFileName.length ? importFileName : $t('flexben.import.no_file_uploaded')}}</div>
        </div>
        <div class="col-12 col-md-6">
          <a :href="exampleLink" target="_blank" download
             class="btn btn-themed-green text-white float-md-right">
            {{$t("flexben.voucher_stocks.btns.import_example")}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportStep1",
  props: {
    importFileName: {
      type: String,
      default: '',
      required: true
    },
    importInvalidReason: {
      type: String,
      default: null,
    },
  },
  computed: {
    exampleLink() {
      return process.env.VUE_APP_FLEXBEN_BASE + 'benefit-invoice/import/example';
    },
  },
  methods: {
    uploadFile($event) {
      this.$emit('uploadFile', $event)
    },
  }
}
</script>

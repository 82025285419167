<template>
  <div>
    <b-modal id="delete-invoice-modal"
             centered
             size="lg"
             :hide-header-close="true"
             headerClass="border-0 text-center"
             bodyClass="modal-body no-border background-white text-center"
             footerClass="modal-footer border-top-0"
             ref="delete-invoice-modal">
      <template v-slot:modal-header>
        <h2 class="mb-0 mx-auto text-capitalize ">{{$t("flexben.requests.modal.title")}}</h2>
      </template>
      <template v-slot:default>
        <div v-if="invalid_reasons" class="row">
          <div class="row mb-0 mx-auto" v-if="invoiceNumber && partnerName">
            <p class="text-danger">{{ invalid_reasons }}</p>
          </div>
        </div>
        <div v-else class="row">
          <div class="row mb-0 mx-auto" v-if="invoiceNumber && partnerName">
            <p v-html="$t('flexben.voucher_stocks.invoicesModalFields.deleteInvoiceConfirmation',{ invoiceNumber: invoiceNumber, partnerName: partnerName })"> </p>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="w-100">
          <button class="btn text-themed mx-auto mt-2" @click="closeModal()">{{
            $t('flexben.general.btn.cancel') }}
          </button>
          <button class="btn btn-themed mx-auto px-4 float-right" @click="confirmAction(invoiceNumber, partnerCui)">{{
            $t('flexben.general.btn.confirm') }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {BModal} from "bootstrap-vue";
import httpFlexbenService from "@/http/http-flexben.js";

export default {
  name: 'DeleteInvoiceModal',
  components: {
    BModal,
  },
  data() {
    return {
      httpService: httpFlexbenService.benefitVoucherInvoicesStocks,
      invoiceNumber: "",
      partnerCui: "",
      partnerName: "",
      invalid_reasons: "",
    };
  },
  methods: {
    showConfirmation(invoiceNumber, partnerCui, partnerName) {
      this.invoiceNumber = invoiceNumber;
      this.partnerCui = partnerCui;
      this.partnerName = partnerName;
      this.$bvModal.show('delete-invoice-modal')
    },
    confirmAction(invoiceNumber, partnerCui) {
      this.httpService.deleteInvoice(invoiceNumber, partnerCui)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            if (data.status === 'success') {
              this.$emit('closeModal');
            } else {
              this.invalid_reasons = data.error_description;
            }
          })
          .catch((error) => {
            this.loaderError = true;
            this.loading = true;
          });
    },
    closeModal() {
      this.invalid_reasons = null;
      this.$refs['delete-invoice-modal'].hide();
    },
  },
};
</script>

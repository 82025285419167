<template>
  <div>
    <div v-show="!this.initialLoading && !this.showForm">
      <div class="row">
        <div class="col-12 col-md-6 flexben-page-title">
          <h1>{{ $t("flexben.benefit.title") }}</h1>
          <p>{{ $t("flexben.benefit.description") }}</p>
        </div>
        <div class="col-12 col-md-6">
          <button class="btn btn-themed px-4 float-right my-2" @click="openForm('create')">
            {{$t("flexben.benefit.add")}}
          </button>
        </div>
      </div>
      <div class="container-bg-white">
        <div class="row p-3">
          <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{$t("flexben.general.filter")}}</span>
          </div>
          <div class="col-12 col-xl-9">
            <div class="row">
              <div class="col-md-4 form-group">
                <label for="legislated_type" class="col-form-label pt-0">{{ $t("flexben.benefit.legislated_type") }}</label>
                <select class="form-control" v-model="params.legislated_type" id="legislated_type"
                        @change="filerValues()">
                  <option :value="null">-</option>
                  <option v-for="type in filter_values.legislated_types" :key="type" :value="type">
                    {{$t("flexben.benefit.legislated_types." + type)}}
                  </option>
                </select>
              </div>
              <div class="col-md-4 form-group">
                <label for="sub_type" class="col-form-label pt-0">{{ $t("flexben.benefit.fields.sub_type") }}</label>
                <select class="form-control" v-model="params.sub_type" id="sub_type"
                        @change="filerValues()">
                  <option :value="null">-</option>
                  <option v-for="type in filter_values.sub_types" :key="type" :value="type">
                    {{$t("flexben.benefit.sub_types." + type)}}
                  </option>
                </select>
              </div>
              <div class="col-md-4 form-group">
                <label for="select_status" class="col-form-label pt-0">{{ $t("flexben.general.status") }}</label>
                <select type="text" class="form-control" v-model="params.status" id="select_status"
                        @change="filerValues()">
                  <option :value="null">-</option>
                  <option v-for="(status, index) in filter_values.statuses" :key="index" :value="status">
                    {{$t("flexben.general.status_type." + status.toLowerCase())}}
                  </option>
                </select>
              </div>
              <div class="col-md-4 form-group">
                <label for="fiscal_treatments" class="col-form-label pt-0">{{ $t("flexben.benefit.fiscal_treatment")
                  }}</label>
                <select type="text" class="form-control" v-model="params.fiscal_treatment" id="fiscal_treatments"
                        @change="filerValues()">
                  <option :value="null">-</option>
                  <option v-for="(item, index) in filter_values.fiscal_treatments" :key="index" :value="item">
                    {{ $t("flexben.benefit.deductibility."+item) }}
                  </option>
                </select>
              </div>

              <div class="col-md-4 form-group">
                <label for="select_partner" class="col-form-label pt-0">{{ $t("flexben.benefit.fields.partner")
                  }}</label>
                <select class="form-control" v-model="params.partner" id="select_partner"
                        @change="filerValues()">
                  <option :value="null">-</option>
                  <option :value="0">{{ $t('flexben.benefit.no_partner') }}</option>
                  <option v-for="(partner, index) in filter_values.partners" :key="index" :value="index">
                    {{ partner }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 form-group">
                <label for="select_location_availability" class="col-form-label pt-0">{{ $t("flexben.benefit.available_locations")
                  }}</label>
                <select class="form-control" v-model="params.location_availability" id="select_location_availability"
                        @change="filerValues()">
                  <option v-for="(location) in filter_values.location_availability" :key="location" :value="location">

                    {{$t("flexben.benefit.location_availability." + location)}}
                  </option>
                </select>
              </div>
              <div class="col-md-4 form-group">
                <label for="select_category_type" class="col-form-label pt-0">{{ $t("flexben.categories.filters.type") }}</label>
                <select class="form-control" v-model="params.category_type" id="select_category_type">
                  <option :value="null">-</option>
                  <option v-for="(category, index) in filter_values.category_types" :key="index" :value="category">
                    {{$t("flexben.categories.filters." + category)}}
                  </option>
                </select>
              </div>
              <div v-if="params.category_type" class="col-md-4 form-group">
                <label for="select_category" class="col-form-label pt-0">{{ $t("flexben.general.category") }}</label>
                <select v-if="params.category_type === 'general'" class="form-control" v-model="params.category" id="select_category"
                        @change="filerValues()">
                  <option :value="null">-</option>
                  <option  v-for="(category, index) in filter_values.categories" :key="category.id" :value="index">
                    {{ category[locale] }}
                  </option>

                </select>
                <select v-else class="form-control" v-model="params.category" id="select_category"
                        @change="filerValues()">
                  <option :value="null">-</option>
                  <option  v-for="(category, index) in filter_values.categories_client" :key="category.id" :value="index">
                    {{ category[locale] }}
                  </option>

                </select>
              </div>
            </div>
          </div>
          <div>
        </div>
        <div class="col-12 mt-2">
            <a class="btn btn-themed float-right px-4" :class="{'disabled':benefits.data.length == 0}"
                 :href="exportUrl + '?lang=' + this.locale + authorizationTokenString "
                 target="_blank"
                 download
                 @click="(event)=>{if(benefits.data.length == 0) {event.preventDefault();}}">
                {{$t("flexben.benefit.btn.export")}}
              </a>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12 col-md-6">
            <h3 class="pl-0 mt-0">{{ $t("flexben.benefit.title") }}</h3>
          </div>
          <div class="col-12 col-md-6">
            <input type="text"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>
          <div class="col-12">
            <div class="table-responsive scaled-table hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td @click="sortBy('id')" class="cursor-pointer">
                    #Id
                  </td>
                  <td @click="sortBy('name')" class="cursor-pointer">
                    {{ $t("flexben.general.name") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortByField === 'name' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                  </td>
                  <td @click="sortBy('start_use_date')" class="cursor-pointer">
                    {{ $t("flexben.benefit.valid_from") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortByField === 'start_use_date' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                  </td>
                  <td>{{ $t("flexben.benefit.fiscal_treatment") }}</td>
                  <td>{{ $t("flexben.benefit.legislated_type") }}</td>
                  <td  @click="sortBy('sub_type')" class="cursor-pointer">
                    {{ $t("flexben.benefit.fields.sub_type") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortByField === 'sub_type' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                  </td>
                  <td  @click="sortBy('partner_id')" class="cursor-pointer">
                    {{ $t("flexben.benefit.fields.partner") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortByField === 'partner_id' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                  </td>
                  <td>{{ $t("flexben.general.categories") }}</td>
                  <td @click="sortBy('status')" class="cursor-pointer">
                    {{ $t("flexben.general.status") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortByField === 'status' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                  </td>
                  <td>{{ $t("flexben.benefit.available_locations") }}</td>
                </tr>
                </thead>
                <tbody v-if="benefits.data.length > 0">
                <tr class="trhover parent" v-for="(item, index) in benefits.data" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    <img class="flx-table-image mr-2" :src="item.image_url"/>
                    <span class="align-middle text-ellipsis-15">{{item.name}}</span>
                  </td>
                  <td>{{ moment(item.start_use_date, 'DD/MM/yyyy') }}</td>
                  <td>{{ $t("flexben.benefit.deductibility."+item.fiscal_treatment) }}</td>
                  <td>{{$t(item.is_legislated ? "flexben.benefit.legislated_types.1" :
                    "flexben.benefit.legislated_types.0")}}
                  </td>
                  <td>{{ (item.sub_type) ? $t("flexben.benefit.sub_types." + item.sub_type) : '-'}}</td>
                  <td>{{ (item.partner_id) ? item.partner.name : '-'}}</td>
                  <td class="flx-benefit-categories">
                      <span class="flx-category text-ellipsis-15 mr-1" v-for="category in item.categories"
                            :key="category.id">
                        <template v-if="category.name != null ">
                          {{ category.name[locale] }}
                        </template>
                      </span>
                  </td>
                  <td>
                    <span :class="'benefit-status-'+item.status.toLocaleLowerCase()" class="benefit-status m-0">{{ $t("flexben.general.status_type." + item.status.toLocaleLowerCase()) }}</span>
                  </td>
                  <td>
                    {{$t("flexben.benefit.location_availability." + item.location_availability)}}
                  </td>
                  <td>
                    <button class="btn action-button" @click="openForm('update', index)">
                      {{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="trhover">
                  <td colspan="8" class="text-center gri-cell">{{$t('flexben.general.no_results_found')}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="benefits.current_page"
              :currentPage="benefits.current_page"
              :totalItems="benefits.total"
              :startItem="benefits.from"
              :endItem="benefits.to"
              :itemsPerPage="benefits.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>

    <BenefitForm v-if="showForm" :formAction="formAction" :itemId="itemId"
                 @clearForm="clearForm"/>

    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import moment from "moment";
import Cookies from "js-cookie";
import BenefitForm from "@/components/flexben/benefits/BenefitForm";

export default {
  name: 'BenefitsIndex',
  components: {BenefitForm},
  data() {
    return {
      initialLoading: true,
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.benefitsService,
      globalVariables: httpFlexbenService.globalVariablesService.baseImagePreview,
      locale: this.$store.state.language,
      exportUrl: httpFlexbenService.exports.benefits.url,
      // filtering
      filter_values: {
        legislated_types: [],
        fiscal_treatments: [],
        sub_types: [],
        category_types: [],
        categories: [],
        categories_client: [],
        statuses: [],
        partners: [],
        location_availability: [],
      },
      searchString: '',
      reverseSort: false,
      sortByField: null,
      params: {
        legislated_type: null,
        sub_type: null,
        fiscal_treatment: null,
        type: null,
        category_type: null,
        category: null,
        status: null,
        partner: null,
        page: 1,
        perPage: 20,
        sort_by: null,
        sort_direction: null,
        location_availability: 0
      },
      activeFilter: {
        filter_type: null,
        filter_category: null,
        filter_status: null
      },
      benefits: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      formAction: '',
      showForm: false,
      itemIndex: null,
      itemId: null
    }
  },
  created() {
    let permissions = this.flxUserLevel();
    if (permissions.hasAccess && permissions.userLevel === 'admin') {
      this._loadList();
    } else {
      window.location.href = '/company';
    }
  },
  beforeUpdate() {
    this.locale = this.$store.state.language;
  },
  computed: {
    authorizationTokenString: function () {
      return '&authorization_token=' + btoa(Cookies.get('token'))
    }
  },
  methods: {
    _loadList() {
      this.loading = true;
      this.httpService
        .loadList(this.params)
        .then((response) => {
          this.benefits.data = response.data.list.data;
          this.benefits.current_page = response.data.list.current_page;
          this.benefits.to = response.data.list.to;
          this.benefits.perPage = response.data.list.perPage;
          this.benefits.lastPage = response.data.list.lastPage;
          this.benefits.total = response.data.list.total;
          this.initialLoading = true;
          this.loading = false;
          if (this.initialLoading) {
            this.initialLoading = false;
            this.filter_values = {
              legislated_types: response.data.filters.legislated_types,
              fiscal_treatments: response.data.filters.fiscal_treatments,
              sub_types: response.data.filters.sub_types,
              category_types: response.data.filters.category_types,
              categories: response.data.filters.categories,
              categories_client: response.data.filters.categories_client,
              statuses: response.data.filters.statuses,
              partners: response.data.filters.partners,
              location_availability: response.data.filters.location_availability,
            };
          }
        });
    },
    filerValues() {
      this._loadList();
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverseSort = false;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList(this.params)
      this.sortByField = fieldName;

      this.reverseSort = !this.reverseSort;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this._loadList();
    },
    search() {
      this.params = {};
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this._loadList();
      } else if (this.searchString.length === 0) {
        this._loadList();
      }
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    openForm(action, index = null) {
      this.formAction = action;
      if (action === 'update') {
        this.itemIndex = index;
        this.itemId = this.benefits.data[index].id;
        this.showForm = true;
      }

      if (action === 'create') {
        this.itemId = null;
        this.showForm = true;
      }
    },
    clearForm(formModified = false) {
      this.formAction = '';
      this.showForm = false;
      this.detailIndex = null;
      this.itemId = null;
      if (formModified) {
        this.resetFilters();
        this._loadList()
      }
    },
    resetFilters() {
      this.params = {
        legislated_type: null,
        sub_type: null,
        fiscal_treatment: null,
        type: null,
        category: null,
        status: null,
        partner: null,
        page: 1,
        perPage: 20,
        sort_by: null,
        sort_direction: null,
        location_availability: 0,
      };
    },
  },
};
</script>

<style src="@/assets/styles/beneficiary.css"></style>
<style src="@/assets/styles/flexben.css"></style>

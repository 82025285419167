var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.company)?_c('div',{staticClass:"menu-items"},[(
        (this.company.role === 'FAC' || this.company.role === 'FLX' || this.company.role === 'HLP') &&
        _vm.user.id !== 57647
      )?_c('a',{staticClass:"search-icon cursor-pointer",class:this.menuSelected.companySearch && 'search-active',attrs:{"href":"/company/search"}},[_vm._v(_vm._s(_vm.$t("searchName")))]):_vm._e(),(this.company.company_id !== 0 && this.company.role !== 'FAC' && this.company.role !== 'FLX' && this.company.role !== 'FLS')?_c('a',{staticClass:"dashboard cursor-pointer",class:this.menuSelected.dashboard && 'dashboard-active',attrs:{"href":"/customer/dashboard"}},[_vm._v(_vm._s(_vm.$t("dashboardName")))]):_vm._e(),(this.company.company_id !== 0)?_c('a',{staticClass:"orders cursor-pointer",class:this.menuSelected.orders && 'orders-active',attrs:{"href":"/customer/orders"}},[_vm._v(_vm._s(_vm.$t("ordersName")))]):_vm._e(),(_vm.showFlxMenu)?_c('FlexbenMenu',{attrs:{"menu-selected":_vm.menuSelected}}):_vm._e(),(_vm.showSuperUpMenu)?_c('SuperUpMenu',{attrs:{"menu-selected":_vm.menuSelected}}):_vm._e(),(this.company.company_id !== 0)?_c('a',{staticClass:"beneficiaries cursor-pointer",class:this.menuSelected.beneficiaries && 'beneficiaries-active',attrs:{"href":"/customer/beneficiaries"}},[_vm._v(_vm._s(_vm.$t("beneficiariesName")))]):_vm._e(),(this.company.company_id !== 0)?_c('a',{staticClass:"points cursor-pointer",class:this.menuSelected.deliveryPoints && 'points-active',attrs:{"href":"/customer/delivery_points"}},[_vm._v(_vm._s(_vm.$t("deliveryPointsName")))]):_vm._e(),(this.company.company_id !== 0)?_c('a',{staticClass:"invoices cursor-pointer",class:this.menuSelected.invoices && 'invoices-active',attrs:{"href":"/customer/invoices"}},[_vm._v(_vm._s(_vm.$t("invoicesName")))]):_vm._e(),_c('a',{staticClass:"amanage cursor-pointer",class:this.menuSelected.account && 'amanage-active',attrs:{"href":"/customer/account"}},[_vm._v(_vm._s(_vm.$t("settingsName")))]),(
        this.company.company_id !== 0 &&
        !_vm.user.username.includes('@') &&
        this.company &&
        this.company.role !== 'FAC' &&
        !this.company.state_owned &&
        this.company.user_config &&
        this.company.user_config.roles &&
        this.company.user_config.roles.includes('EDITOR')
      )?_c('a',{staticClass:"cmanage cursor-pointer",class:this.menuSelected.manageCompany && 'cmanage-active',attrs:{"href":"/company/manage"}},[_vm._v(_vm._s(_vm.$t("companyManagementTitle")))]):_vm._e(),(this.company.role !== 'FAC' || this.company.role !== 'FLX')?_c('a',{staticClass:"contact cursor-pointer",class:this.menuSelected.contact && 'contact-active',attrs:{"href":"/customer/contact"}},[_vm._v(_vm._s(_vm.$t("contactName")))]):_vm._e(),(this.company.role !== 'FAC' || this.company.role !== 'FLX')?_c('a',{staticClass:"faq cursor-pointer",class:this.menuSelected.faq && 'faq-active',attrs:{"href":"/customer/faq"}},[_vm._v(_vm._s(_vm.$t("faqName")))]):_vm._e(),(this.company.role !== 'FAC' || this.company.role !== 'FLX')?_c('a',{staticClass:"term cursor-pointer",class:this.menuSelected.terms && 'term-active',attrs:{"href":"/customer/terms"}},[_vm._v(_vm._s(_vm.$t("termsAndConditionsName")))]):_vm._e(),_c('a',{staticClass:"logout",attrs:{"href":"#"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(_vm._s(_vm.$t("logoutName")))])],1):_vm._e(),(
      (!_vm.flxUserLevel().hasAccess ||
        (_vm.flxUserLevel().hasAccess && this.company.can_order_up) ||
        this.company.role === 'FAC') &&
      this.company.company_id !== 0
    )?_c('a',{staticClass:"notification-action step0",attrs:{"href":"/customer/new_order"}},[_vm._v(" "+_vm._s(_vm.$t("newOrderName"))+" ")]):_vm._e(),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"socials"},[_c('a',{staticClass:"facebook",attrs:{"href":"https://www.facebook.com/UpRomania"}}),_c('a',{staticClass:"linkedin",attrs:{"href":"https://www.linkedin.com/company/up-romania"}}),_c('a',{staticClass:"youtube",attrs:{"href":"https://www.youtube.com/c/UpRomaniaBucuresti"}})])
}]

export { render, staticRenderFns }
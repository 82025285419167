<template>
  <div>
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div class="row mb-3">
      <div class="col-12 col-md-6 flexben-page-title">
        <h1>{{ (formAction==='create') ? $t('flexben.fixed_benefits.new') : $t('flexben.fixed_benefits.edit') }}</h1>
      </div>
      <div class="col-12 col-md-6 d-inline-flex">
        <span class="ml-auto">
          <button class="btn btn-delete text-danger" @click="modalAct('delete')"
                  v-if="formAction==='update'">
          <font-awesome-icon icon="trash-alt"/>
          {{ $t('flexben.general.delete') }}
        </button>
        <button class="btn btn-themed" @click="modalAct('form_submit')">{{ $t('flexben.general.btn.save') }}
        </button>
        </span>
      </div>
    </div>
    <div class="row mt-1" v-if="checkNotEmptyObj(errorsBag)">
      <div class="col-12">
        <div class="alert alert-danger alert-dismissible mb-0">
          <button type="button" class="close" data-dismiss="alert" @click="closeErrorButton = false">&times;</button>
          <form-error-list :errorBag="errorsBag"></form-error-list>
        </div>
      </div>
    </div>
    <div class="container-bg-white mt-3" :key="formKey">
      <div class="row p-3">
        <div class="col-12 d-inline-flex align-content-center mb-3">
          <h3 class="mt-0 pt-1 pl-0">{{ $t('flexben.general.details') }}</h3>
        </div>

        <div class="col-12 col-md-6 col-xl-4 d-flex align-items-start justify-content-center mb-1">
          <div class="image-container cursor-pointer d-flex align-items-center" @click="$refs.image_input.click()">
            <div class="image-changed" v-if="imageChanged">{{$t("flexben.benefit.image_changed")}}</div>
            <img class="img-fluid upload-image"
                 :src="(formData.image!==null) ? image_preview : formData.image_url"/>
            <div class="upload-image-middle-container">
              <div class="upload-image-text">{{$t("flexben.benefit.upload_image")}}</div>
            </div>
            <input type="file" class="hide" ref="image_input" accept=".png,.jpeg,.jpg,.svg,.jfif" @change="setImageFile($event)">
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-8 mb-2">
          <div class="row">
            <div class="col-xl-6 form-group" v-for="(obj, index) in formData.name" :key="index">
              <label :for="'name_' + obj.lang_code" class="form-fields-required col-form-label pt-0">{{
                $t("flexben.fixed_benefits.table.name") }} - {{obj.lang_code}}</label>
              <input :id="'name_' + obj.lang_code" type="text" class="form-control"
                     v-model="obj.value"
                     :class="{'border-danger':(Object.keys(errorsBag).includes('name|'+obj.lang_code))}"
                     :maxlength="63"
              >
              <div class="text-muted mt-2 float-right">
                {{ obj.value.length }} / 63
              </div>
              <div v-if="obj.value.length === 0" class="text-danger" >
                {{$t("flexben.formErrorReasons.at_list_1_character")}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 form-group" v-for="(obj, index) in formData.description" :key="index">
              <label :for="'description_' + obj.lang_code" class="col-form-label pt-0">{{
                $t("flexben.fixed_benefits.table.description") }} - {{obj.lang_code}}</label>
              <textarea :id="'description_' + obj.lang_code" type="text" class="form-control-textarea w-100 my-0"
                        rows="6"
                        v-model="obj.value"
                        :maxlength="2000"
                        :class="{'border-danger':(Object.keys(errorsBag).includes('description|'+obj.lang_code))}"></textarea>
              <div class="text-muted mt-2 float-right">
                {{ obj.value.length }} / 2000
              </div>
              <div v-if="obj.value.length === 0" class="text-danger" >
                {{$t("flexben.formErrorReasons.at_list_1_character")}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 d-inline-flex">
        <span class="ml-auto">
          <button class="btn btn-delete text-danger" @click="modalAct('delete')"
                  v-if="formAction==='update'">
          <font-awesome-icon icon="trash-alt"/>
          {{ $t('flexben.general.delete') }}
        </button>
        <button class="btn btn-themed" @click="modalAct('form_submit')">{{ $t('flexben.general.btn.save') }}
        </button>
        </span>
      </div>
    </div>

    <confirm-modal :openModal="modalOpen"
                   :modalAction="modalAction"
                   :formSuccess="modalFormSuccess"
                   :endMessage="modalEndMessage"
                   @closeModal="closeModal"
                   @confirmAction="confirmAction"/>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import ConfirmModal from "@/components/flexben/fixed_benefits/modals/ConfirmModal";
import FormErrorList from "@/components/fragments/FormErrorList";

export default {
  name: "BenefitGroupDetail",
  components: {
    "confirm-modal": ConfirmModal,
    "form-error-list": FormErrorList
  },
  props: {
    companyId: {
      type: Number,
      // required: true,
    },
    formAction: {
      type: String,
      required: true,
    },
    groupId: {
      type: Number,
      // required: true,
    },
  },
  data() {
    return {
      httpService: httpFlexbenService.fixedBenefitsService,
      initialLoading: true,
      loading: false,
      loaderError: false,
      locale: i18n.locale,
      formKey: 0,

      options: {
        available_languages: [],
      },
      formData: {
        id: null,
        company: null,
        name: [],
        description: [],
        image: null,
        image_url: httpFlexbenService.globalVariablesService.baseImagePreview
      },
      imageChanged: false,
      image_preview: null,
      isFormModified: false,
      errorsBag: {},
      modalOpen: false,
      modalFormSuccess: false,
      modalAction: '',
      modalEndMessage: '',
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.isFormModified = true;
      }
    }
  },
  created() {
    this.toggleParentLoading(true);
    if (this.formAction === 'update' && this.groupId !== null) {
      this.getFixedBenefit();
    } else if (this.formAction === 'create') {
      this.getOptions();
    } else {
      this.$emit('clearForm')
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  mounted() {
    this.isFormModified = false;
  },
  methods: {
    getFixedBenefit() {
      this.httpService.details(this.groupId)
        .then(function (response) {
          this.formData.id = response.data.id;
          for (let langCode in response.data.name) {
            this.formData.name.push({
              lang_code: langCode,
              value: response.data.name[langCode]
            });
            this.formData.description.push({
              lang_code: langCode,
              value: (response.data.description && response.data.description[langCode]) ? response.data.description[langCode] : ''
            });
          }
          this.formData.image_url = response.data.image_url;
          this.formData.company = response.data.company_id;
          // this.options.available_languages = response.data.options.available_languages;
          this.toggleParentLoading(false);
        }.bind(this))
        .catch(function (error) {
          this.toggleParentLoading(true, true);
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this));
    },
    getOptions() {
      this.httpService.createOptions({company: this.companyId})
        .then(function (response) {
          this.options.available_languages = response.data.available_languages;
          for (let index in response.data.available_languages) {
            this.formData.name.push({
              lang_code: response.data.available_languages[index],
              value: ''
            });
            this.formData.description.push({
              lang_code: response.data.available_languages[index],
              value: ''
            });
          }
          this.formData.company = this.companyId;
          this.toggleParentLoading(false);
        }.bind(this))
        .catch(function (error) {
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this));
    },
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.$emit('clearForm');
      }
    },
    toggleBenefitId(benefitId) {
      if (this.formData.benefit_ids.includes(benefitId)) {
        let benefitIndex = this.formData.benefit_ids.indexOf(benefitId);
        this.formData.benefit_ids.splice(benefitIndex, 1);
      } else {
        this.formData.benefit_ids.push(benefitId);
      }
    },
    modalAct(action) {
      this.modalAction = action;
      this.modalOpen = true;
    },
    confirmAction(action) {
      if (action === 'go_back') {
        this.closeModal();
        this.isFormModified = false;
        this.goBack();
      }
      if (action === 'form_submit') {
        this.toggleParentLoading(true);
        this.modalOpen = false;
        if (this.modalAction === 'form_submit') {
          if (this.formAction === 'update') {
            this._updateItem();
          } else {
            this._createItem();
          }
        } else if (this.modalAction === 'delete') {
          this._deleteItem();
        }
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modalFormSuccess) {
          this.isFormModified = false;
          this.$emit('clearForm', true);
        }
      }
    },
    _createItem() {
      this.errorsBag = {};
      this.toggleParentLoading(false);
      this.httpService.create(this._prepareFormData())
        .then(function (response) {
          this.modalFormSuccess = true;
          this.modalAction = 'end';
          this.toggleParentLoading(false);
          this.modalOpen = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.toggleParentLoading(false);
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.toggleParentLoading(true, true);
          }
        }.bind(this));
    },
    _updateItem() {
      this.errorsBag = {};
      this.httpService.update(this.formData.id, this._prepareFormData())
        .then(function (response) {
          this.modalFormSuccess = true;
          this.modalAction = 'end';
          this.toggleParentLoading(false);
          this.modalOpen = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.toggleParentLoading(false);
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.toggleParentLoading(true, true);
          }
        }.bind(this));
    },
    _deleteItem() {
      this.errorsBag = {};
      this.httpService.delete(this.formData.id)
        .then(function (response) {
          this.modalFormSuccess = true;
          this.modalAction = 'end';
          this.toggleParentLoading(false);
          this.modalOpen = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.error_fields;
            this.toggleParentLoading(false);
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.toggleParentLoading(true, true);
          }
        }.bind(this));
    },
    closeModal() {
      this.modalAction = '';
      this.modalOpen = false;
    },
    toggleParentLoading(toggle, loaderError = false) {
      this.$emit('triggerLoading', toggle, loaderError);
    },
    setImageFile() {
      this.errorsBag = {};
      if (this.$refs.image_input.accept.indexOf(this.$refs.image_input.files[0].type.split('/')[1]) > 0) {

        let reader = new FileReader();
        reader.readAsDataURL(this.$refs.image_input.files[0]);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            if (img.width !== img.height) {
              this.errorsBag = {image: 'image_square'};
            } else if (img.width !== 500 || img.height !== 500) {
              this.errorsBag = {image: 'image_500_500'};
            } else {
              this.formData.image = this.$refs.image_input.files[0];
              this.imageChanged = true;
              if (typeof this.formData.image !== "undefined") {
                this.image_preview = URL.createObjectURL(this.formData.image);
              }
            }
          }
          img.src = evt.target.result;
        }
      } else {
        this.errorsBag = {image: 'image_type_supported'}
      }
    },
    _prepareFormData() {
      let formData = new FormData();
      formData.append('image', this.formData.image);
      let name = {},
        description = {},
        index = 0;
      for (index in this.formData.name) {
        name[this.formData.name[index].lang_code] = this.formData.name[index].value;
      }
      for (index in this.formData.description) {
        description[this.formData.description[index].lang_code] = this.formData.description[index].value;
      }
      formData.append('name', JSON.stringify(name));
      formData.append('description', JSON.stringify(description));
      formData.append('company', this.formData.company);
      return formData;
    }
  }
}
</script>
<style scoped src="@/assets/styles/flexben.css"></style>

